@use 'sass:math';

.form_formframework {
  .container {
    display: contents;
  }

  &:has(.form-contact) {
    .container {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
      }
    }

    .form-content,
    .form-contact {
      width: percentage(math.div(12, 12));

      @include media-breakpoint-up(xl) {
        width: percentage(math.div(6, 12));
      }
    }

    .form-content {
      .header > * {
        margin-bottom: $grid-gutter-width * 0.75;
      }

      @include media-breakpoint-up(xl) {
        padding-right: $grid-gutter-width * 3.75;

        .header > * {
          margin-bottom: $grid-gutter-width;
        }
      }
    }
  }

  .fieldset-form {
    margin-bottom: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(xl) {
      column-gap: $grid-gutter-width;
    }

    .form-group {
      margin-bottom: $grid-gutter-width;
      width: 100%;

      .info {
        padding-left: $grid-gutter-width * 0.125;
        margin-bottom: $grid-gutter-width;
        font-size: 14px;
        line-height: 26px;
        color: $darkgrey;

        @include media-breakpoint-up(lg) {
          font-size: 16px;
        }
      }
    }

    .clearfix {
      padding-left: $grid-gutter-width * 0.125;
      margin-bottom: $grid-gutter-width;
      font-size: 14px;
      line-height: 26px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      &:not(.data-protection) {
        color: $darkgrey;
      }

      &.data-protection {
        line-height: 1.5;
      }
    }

    .control-label {
      margin-bottom: 0;
      border: 1px solid transparent;
      width: 100%;

      > span:first-of-type {
        transform: translateY(36.2px) scale(1);
        transform-origin: left;
        display: flex;
        padding-left: $grid-gutter-width * 0.125;
        transition: transform 0.15s ease-in-out;
        color: $grey;

        @include media-breakpoint-up(lg) {
          transform: translateY(42.2px) scale(1);
        }

        &:has(+ .form-control:focus),
        &:not(:has(+ .form-control:placeholder-shown)) {
          transform: translateY(10px) scale(0.75);
          color: $darkgrey;

          &:hover {
            cursor: default;
          }
        }

        &:hover {
          cursor: text;
        }
      }
    }

    .form-select {
      color: __get($theme-colors, 'primary');
      border-bottom: 1px solid $primary;
    }

    .form-control {
      width: 100%;
      padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.125 $grid-gutter-width * 0.25 $grid-gutter-width * 0.125;
      border: 1px solid transparent;
      border-bottom-color: $primary;
      transition: none;

      &:focus {
        border: 1px solid transparent;
        border-bottom: 2px solid $primary;
      }

      &::placeholder {
        color: transparent;
      }

      &:not(:placeholder-shown) {
        border-bottom: 1px solid $primary;
      }
    }

    textarea.form-control {
      height: calc((1.5em + 0.75rem + 4px) * 3);
    }
  }
}

input:not(.form-control) {
  position: absolute;
  top: -100vh;
  width: 0;
}

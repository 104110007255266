@use 'sass:math';

.navigation {
  &-wrapper {
    --bg-color-wrapper: #{__get($colors, 'white')};

    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: var(--bg-color-wrapper);
    height: 100dvh;
    max-height: 100dvh;
    overflow-y: scroll;
    transition-property: translate, display;
    transition-behavior: allow-discrete;
    translate: 100dvw 0;

    @include motion-safe() {
      transition-duration: __get($animation-settings, 'speed');
    }

    @include media-breakpoint-up(xl) {
      --bg-color-wrapper: transparent;
    }

    &.open {
      display: block;
      translate: 0 0;

      @starting-style {
        translate: 100dvw 0;
      }

      &:has(.navigation-main-submenu.open) {
        overflow-y: hidden;
      }
    }

    @include media-breakpoint-up(md) {
      width: 50%;
      right: 0;
    }

    @include media-breakpoint-up(xl) {
      display: block;
      position: unset;
      width: auto;
      overflow-y: visible;
      align-content: center;
      translate: none;
      height: unset;
    }
  }

  &-main {
    margin-top: var(--header-height);

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }

    > ul {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        gap: $grid-gutter-width * 0.5;
      }

      > li {
        --anchor-decoration: none;
        --underline-offset: 7px;
        --underline-thickness: 2px;

        color: __get($theme-colors, 'primary');

        &:hover,
        &.active,
        &:has(.navigation-main-submenu.open) {
          --anchor-decoration: underline;

          color: __get($theme-colors, 'red');

          @include media-breakpoint-up(xl) {
            --anchor-decoration: underline;
            --underline-offset: 8px;
            --underline-thickness: 2px;
          }
        }

        &:hover,
        &:has(.navigation-main-submenu.open) {
          @include media-breakpoint-up(xl) {
            > .navigation-main-submenu__item > .navigation-main-toggle-submenu {
              .ico-chevron {
                transform: translateY($grid-gutter-width * 0.125);
              }
            }
          }
        }

        > .navigation-main-submenu__item {
          > a {
            font-size: 28px;
            line-height: 150%;
            font-weight: 700;
            color: currentColor;
            text-decoration: var(--anchor-decoration);
            text-underline-offset: var(--underline-offset);
            text-decoration-thickness: var(--underline-thickness);
            text-underline-color: __get($theme-colors, 'red');
            transition-property: color;

            @include media-breakpoint-up(xl) {
              font-size: 18px;
              font-weight: 600;
              line-height: 150%;
              position: relative;

              @include motion-safe() {
                transition-duration: __get($animation-settings, 'speed');
              }
            }
          }

          > .navigation-main-toggle-submenu {
            background-color: __get($colors, 'white');

            color: currentColor;

            @include media-breakpoint-up(xl) {
              background-color: transparent;

              &:hover {
                .ico-chevron {
                  transform: translateY($grid-gutter-width * 0.125);
                }
              }
            }

            .ico-arrow {
              display: block;

              @include media-breakpoint-up(xl) {
                display: none;
              }
            }

            .ico-chevron {
              display: none;

              @include media-breakpoint-up(xl) {
                display: block;
                transform: translateY(0);
                transition-property: transform;

                @include motion-safe() {
                  transition-duration: __get($animation-settings, 'speed');
                }
              }
            }
          }
        }
      }
    }

    &-submenu-level-1 {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: __get($colors, 'white');
      overflow-y: scroll;
      transition-property: translate, display;
      transition-behavior: allow-discrete;
      translate: 100dvw 0;

      @include motion-safe() {
        transition-duration: __get($animation-settings, 'speed');
      }

      &.open {
        display: block;
        translate: 0 0;

        @starting-style {
          translate: 100dvw 0;
        }

        &:has(.navigation-main-submenu.open) {
          overflow-y: hidden;

          @include media-breakpoint-up(xl) {
            overflow-y: scroll;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        top: 100%;
        height: auto;
        max-height: calc(100dvh - var(--header-height));
        translate: 0 calc(calc(var(--header-height) * -1) / 2);
        transition-property: display, translate, opacity;
        opacity: 0;

        @include motion-safe() {
          transition-duration: __get($animation-settings, 'speed');
        }

        &.open {
          opacity: 1;

          @starting-style {
            translate: 0 calc(var(--header-height) * -1);
            opacity: 0;
          }
        }
      }

      > .navigation-main-submenu__inner {
        --padding-top: 0;
        --padding-bottom: 0;

        @include media-breakpoint-down(lg) {
          max-width: unset;
          padding: 0;
        }

        @include media-breakpoint-up(xl) {
          --padding-top: #{$grid-gutter-width * 1.25};
          --padding-bottom: #{$grid-gutter-width * 2.75};
        }

        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);

        > ul {
          @include media-breakpoint-up(xl) {
            display: grid;
            position: relative;
            grid-template-columns: minmax(auto, percentage(math.div(7, 12))) 1fr;
            grid-template-rows: repeat(var(--items-count), minmax(0, max-content)) 0fr;
            transition-property: grid-template-rows;

            @include motion-safe() {
              transition-duration: __get($animation-settings, 'speed');
            }

            &:has(.navigation-main-submenu.open) {
              grid-template-rows: repeat(var(--items-count), minmax(0, max-content)) 1fr;
            }
          }

          @include media-breakpoint-up(xxl) {
            max-width: percentage(math.div(10, 12));
          }

          > li {
            --anchor-decoration: none;
            --underline-offset: 7px;
            --underline-thickness: 2px;

            color: __get($theme-colors, 'primary');

            @include media-breakpoint-up(xl) {
              --underline-thickness: 5px;

              display: contents;
            }

            &:hover,
            &.active,
            &:has(.navigation-main-submenu.open) {
              --anchor-decoration: underline;
              color: __get($theme-colors, 'red');
            }

            &:hover,
            &:has(.navigation-main-submenu.open) {
              > .navigation-main-submenu__item {
                > button {
                  @include media-breakpoint-up(xl) {
                    transform: translateX($grid-gutter-width * 0.5);
                  }
                }
              }
            }

            &:last-child {
              > .navigation-main-submenu__item {
                margin-bottom: 0;
              }
            }

            > .navigation-main-submenu__item {
              height: 100%;

              @include media-breakpoint-up(xl) {
                grid-column: 1;
                height: auto;
                margin-bottom: $grid-gutter-width;
              }

              > a {
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                color: currentColor;
                text-decoration: var(--anchor-decoration);
                text-underline-offset: var(--underline-offset);
                text-decoration-thickness: var(--underline-thickness);
                text-decoration-color: __get($theme-colors, 'red');
                transition-property: color;

                @include media-breakpoint-up(xl) {
                  font-size: 30px;
                  font-weight: 700;

                  @include motion-safe() {
                    transition-duration: __get($animation-settings, 'speed');
                  }
                }
              }

              > button {
                background-color: __get($colors, 'white');
                color: currentColor;
                transition-property: transform;

                @include motion-safe() {
                  transition-duration: __get($animation-settings, 'speed');
                }

                .ico-arrow {
                  display: block;
                  width: 24px;
                  height: 24px;

                  @include media-breakpoint-up(xl) {
                    width: 40px;
                    height: 40px;
                  }
                }

                .ico-chevron {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &-submenu-level-2 {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: __get($colors, 'white');
      overflow-y: scroll;
      transition-property: translate, display;
      transition-behavior: allow-discrete;
      translate: 100dvw 0;

      @include motion-safe() {
        transition-duration: __get($animation-settings, 'speed');
      }

      &.open {
        display: block;
        translate: 0 0;

        @starting-style {
          translate: 100dvw 0;
        }

        &:has(.navigation-main-submenu.open) {
          overflow-y: hidden;
        }
      }

      @include media-breakpoint-up(xl) {
        grid-column: 2;
        grid-row: 1 / calc(var(--items-count) + 2);
        position: relative;
        translate: 10dvw 0;
        transition-property: translate, display, opacity;
        opacity: 0;

        &.open {
          opacity: 1;

          @starting-style {
            translate: 10dvw 0;
            opacity: 1;
          }
        }
      }

      > .navigation-main-submenu__inner {
        @include media-breakpoint-down(lg) {
          max-width: unset;
          padding: 0;
        }

        // focus fix
        @include media-breakpoint-up(xl) {
          padding-top: 5px;
        }

        > ul {
          @include media-breakpoint-up(xl) {
            display: flex;
            flex-direction: column;
            row-gap: $grid-gutter-width * 0.5;
          }

          > li {
            --anchor-decoration: none;
            --underline-offset: 7px;
            --underline-thickness: 2px;

            color: __get($theme-colors, 'primary');

            &:hover,
            &.active {
              --anchor-decoration: underline;

              color: __get($theme-colors, 'red');
            }

            > .navigation-main-submenu__item {
              > a {
                font-size: 18px;
                font-weight: 600;
                line-height: 150%;
                color: currentColor;
                text-decoration: var(--anchor-decoration);
                text-underline-offset: var(--underline-offset);
                text-decoration-thickness: var(--underline-thickness);

                @include media-breakpoint-up(xl) {
                  font-size: 20px;
                  text-decoration-color: __get($theme-colors, 'red');
                  transition-property: color;

                  @include motion-safe() {
                    transition-duration: __get($animation-settings, 'speed');
                  }
                }
              }
            }
          }
        }
      }
    }

    &-close-submenu {
      font-size: 16px;
      line-height: 26px;
      color: __get($theme-colors, 'primary');
      height: var(--header-height);
      z-index: 4;
      position: fixed;
      top: 0;
      width: 100%;
      text-align: left;
    }

    .submenu-title {
      color: __get($theme-colors, 'primary');
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-top: var(--header-height);
    }
  }

  &-meta {
    > ul {
      > li {
        a {
          text-decoration: none;
          font-size: 18px;
          line-height: 150%;
          font-weight: 400;
        }
      }
    }
  }

  &-socials {
    ul {
      gap: $grid-gutter-width * 0.75;
    }
  }
}

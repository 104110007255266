@use 'sass:math';

:root {
  --column-width: 100%;
}

.theme_intro_teaser {
  @extend .container, .my-dg, .my-lg-tg;

  @include media-breakpoint-up(lg) {
    @each $width in (7, 8, 9) {
      .column-width-#{$width} {
        --column-width: #{percentage(math.div($width, 12))};
      }
    }
  }

  .header,
  .bodytext {
    @extend #{$headline-default-classes};

    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xxl) {
      flex: 0 0 var(--column-width);
      max-width: var(--column-width);
    }
  }

  .header {
    .h1,
    h1 {
      margin-bottom: 0;

      & + .heading-small {
        display: block;
        flex: none;
        margin-inline: 0;
        padding-inline: 0;
      }
    }

    & + .bodytext {
      margin-top: $grid-gutter-width * 1.25;

      @include media-breakpoint-up(md) {
        margin-top: $grid-gutter-width * 1.75;
      }

      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width * 2;
      }

      @include media-breakpoint-up(xl) {
        margin-top: $grid-gutter-width * 2.5;
      }

      @include media-breakpoint-up(xxl) {
        margin-top: $grid-gutter-width * 2.75;
      }
    }
  }
}

body {
  position: relative;

  &.no-scroll,
  &:has(.navigation-main-submenu.open) {
    overflow: hidden;
  }

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    inset: 0;
    background: #000;
    transition-property: opacity;
    z-index: -1;

    @include motion-safe() {
      transition-duration: __get($animation-settings, 'speed');
    }
  }

  &:has(.navigation-wrapper.open) {
    &:before {
      opacity: 0.2;
      z-index: 10;
    }
  }

  &:has(.modal-backdrop.show),
  &:has(.tx-supi-overlay):not(.hide) {
    header {
      z-index: 1000;
    }
  }

  @include media-breakpoint-up(xl) {
    &:has(.navigation-main-submenu.open) {
      &:before {
        opacity: 0.2;
        z-index: 10;
      }
    }
  }
}

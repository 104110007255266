li {
  .ce-wrapper &,
  .bodytext & {
    padding: 5px 0;
  }

  .bodytext &,
  .cke_editable & {
    position: relative;
    padding-left: 30px;

    &:before {
      position: absolute;
      left: 0;
      content: '✓ ';
      font-weight: 700;
      font-size: 110%;
    }
  }

  .list-default &,
  .list-unstyled & {
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}

.theme_blockquote {
  @extend .container, .my-half-special-gutter, .my-lg-special-gutter, .text-center, .text-md-left;

  .tmpl-Landingpage & {
    @extend .my-2h, .my-lg-special-gutter-lp;
  }

  &[data-bgcolor] {
    @extend .py-half-special-gutter, .py-lg-small-special-gutter-lp;
  }

  --blockquote-color: $primary;

  @include media-breakpoint-up('xxl') {
    padding: 0 $grid-gutter-width * 4;
  }

  .header {
    @include media-breakpoint-up(sm) {
      padding: 0 $grid-gutter-width * 2;
    }
  }

  blockquote {
    color: var(--blockquote-color);

    @include media-breakpoint-up(sm) {
      padding: 0 $grid-gutter-width * 2;
    }

    &.hasImage {
      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }

    footer {
      color: var(--blockquote-color);
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      line-height: 26px;

      address {
        display: inline;
        font-weight: $font-weight-base;
        margin: 0;
      }

      &:before {
        display: none;
      }
    }
  }

  picture {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.theme_link_cloud {
  @extend .container, .py-dg, .py-lg-tg;

  header {
    @include make-row();

    h2 {
      @extend .col-12, .col-md-10, .offset-md-1;
      margin-bottom: 0;
    }
  }

  ul {
    margin: 0 -#{$grid-gutter-width * 0.5};

    li {
      padding-left: $grid-gutter-width * 0.5;
      padding-right: $grid-gutter-width * 0.5;
    }
  }

  .link-arrow {
    font-size: 1em;
    font-weight: bold;
    hyphens: auto;
  }
}

a,
button,
button[class],
.btn,
[tabindex='-1']:not(.modal) {
  &:focus-visible {
    box-shadow: none;
    outline-offset: 3px;
    outline: 2px dashed #737373;
    opacity: 1;
    transition: none;
    z-index: 1;
  }
}

input[id^='supi'],
input[id^='supi'][type='checkbox'],
input[type='checkbox'] {
  &:focus-visible {
    outline: 0;

    & + label {
      outline: 2px dashed currentColor;
      outline-offset: 3px;
    }
  }
}

/* @TODO: Discuss with team about focus-visible/hover styles for this linked images.
.theme_teaser-item:focus-visible {
  position: relative;
  outline: 0;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
  }
}*/

.supi__buttongroup button[data-toggle] {
  outline-offset: -2px;
  outline-color: currentColor;
}

button[id^='supi'] {
  &:focus-visible {
    outline-color: currentColor;
  }
}

.main-nav__link:focus-visible,
.sub-nav__link:focus-visible,
.product-gallery li a {
  outline-offset: -2px;
}

.btn.btn-gray:focus-visible {
  outline-color: var(--gray-600);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  box-shadow: none;
  outline-offset: 3px;
  outline: 2px dashed currentColor;
  opacity: 1;
  transition: none;
}

.js-focus-visible .btn.btn-gray.focus-visible {
  outline-color: var(--gray-600);
}

.js-focus-visible button[id^='supi'].focus-visible {
  outline-offset: 0;
  outline-color: __get($theme-colors, base);
}

.js-focus-visible .supi__buttongroup button[id^='supi'].focus-visible {
  outline-color: currentColor;
  outline-offset: -2px;
}

.js-focus-visible input[id^='supi'],
.js-focus-visible input[type='checkbox'].compare,
.js-focus-visible input[type='checkbox'].checkbox-compare {
  outline: none;

  & + label {
    outline: 2px dashed transparent;
    outline-offset: 3px;
  }

  &.focus-visible {
    & + label {
      outline-color: currentColor;
    }
  }
}

.js-focus-visible .main-nav__link.focus-visible,
.js-focus-visible .sub-nav__link.focus-visible,
.product-gallery li a.focus-visible {
  outline-offset: -2px;
}

.js-focus-visible .icon.focus-visible {
  outline-offset: 0;
}

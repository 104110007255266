a {
  &:after {
  }

  &:hover,
  &:active,
  &:visited {
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration-color: rgba(0, 0, 0, 0.5);
  }

  // Additional class: link-light
  &.link-light {
    @extend .font-weight-normal;
  }

  // Additional class: prevent default link styling
  &.link-no-style {
    font-weight: inherit;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration-color: rgba(0, 0, 0, 0.5);
    }
    &:not(.link-no-hover-style):hover,
    &:not(.link-no-hover-style):active,
    &:not(.link-no-hover-style):visited {
      text-decoration: underline;
    }
  }
  &.link-unset-color {
    color: inherit;
  }
}

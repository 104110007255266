@use 'sass:math';

.theme_pageteaser {
  @include media-breakpoint-down(md) {
    &:first-child {
      margin-top: __get($spacers, 'fg');
    }
    margin-bottom: __get($spacers, 'fg');
  }
  @include media-breakpoint-up(lg) {
    margin-top: __get($spacers, 'dg');
    margin-bottom: __get($spacers, 'dg');
  }

  &__wrap {
    @extend .border;
    height: 100%;
  }

  &__text {
    width: percentage(math.div(12, 12));

    @include media-breakpoint-up(md) {
      width: percentage(math.div(10, 12));
    }
  }
}

* {
  &:focus {
    outline: none;
  }
}

html {
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

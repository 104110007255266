:root {
  --grid-gutter: 16px;

  @include media-breakpoint-up(xl) {
    --grid-gutter: 32px;
  }
}

.container,
.container-fluid {
  padding-inline: calc(var(--grid-gutter));
}

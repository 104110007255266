.theme_intro_index {
  @extend .container, .py-dg, .py-lg-tg;

  .header {
    @include make-row();

    h1 {
      @extend #{$headline-default-classes};

      margin-bottom: 0;
    }
  }

  img {
    max-width: 100px;

    @include media-breakpoint-up(md) {
      max-width: 100%;
    }
  }
}

@use 'sass:math';

.theme_award_list {
  @extend .my-half-special-gutter, .my-lg-special-gutter, .container, .d-flex, .py-hg, .py-lg-0;

  flex-direction: column;

  .header {
    @extend .col-12, .col-xl-4, .px-0;
  }

  .award-list {
    --columns: 2;
    --column-gap: #{$grid-gutter-width * 0.25};
    --row-gap: 0;

    grid-template-columns: repeat(var(--columns), 1fr);
    display: grid !important;
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);

    @include media-breakpoint-up(md) {
      --columns: 4;
      --row-gap: #{$grid-gutter-width};
      --column-gap: #{$grid-gutter-width * 1.75};
    }

    @include media-breakpoint-up(xl) {
      --columns: 6;
      --column-gap: #{$grid-gutter-width * 1.75};
    }

    &.small {
      @include media-breakpoint-up(lg) {
        margin-left: percentage(math.div(1, 12));
      }
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        --max-height: 112px;

        max-height: var(--max-height);
        width: auto;
        max-width: 100%;

        @include media-breakpoint-up(xl) {
          --max-height: 126px;
        }

        @include media-breakpoint-up(xxl) {
          --max-height: 148px;
        }
      }
    }
  }
}

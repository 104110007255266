@media print {
  figure,
  p,
  .theme_pageTeaser,
  .theme_pageTeaser,
  .theme_teaserimagefullwidth,
  .small-teaser,
  .theme_teaser,
  .theme_logo_list {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  #main-navigation {
    display: none !important;
    ul {
      display: none !important;
    }
  }

  .btn {
    display: none !important;
  }

  .animation {
    opacity: 1 !important;
    --translateY: 0 px !important;
  }
}

.iconSocial {
  display: inline-block;
  fill: currentColor;
}

.iconSocial-facebook {
  height: 24px;
  width: 24px;
}
.iconSocial-github {
  height: 24px;
  width: 24px;
}
.iconSocial-instagram {
  height: 24px;
  width: 24px;
}
.iconSocial-linkedin {
  height: 24px;
  width: 21px;
}
.iconSocial-twitter {
  height: 24px;
  width: 24px;
}
.iconSocial-youtube {
  height: 24px;
  width: 24px;
}

.btn {
  text-decoration: none;
  border-width: 2px;

  &-md {
    padding: 12px 16px;

    @include media-breakpoint-up(xl) {
      padding: 16px 20px;
    }
  }

  &-lg {
    padding: 16px 20px 16px 20px;

    @include media-breakpoint-up(xl) {
      padding: 24px 20px 24px 20px;
    }

    &.link-arrow {
      padding-left: 67px;
    }
  }

  .primary-color & {
    &-primary {
      color: var(--teaser-image-fullwidth-bgcolor);
      border-color: var(--contrast-color);
      background-color: var(--contrast-color);
    }
    &-outline-primary {
      color: var(--contrast-color);
      border-color: var(--contrast-color);
    }
  }
}

[type='submit'] {
  @extend .link-arrow;
  @extend .btn-lg;
}

.frame-type-theme_teaserimagefullwidth,
.frame-type-theme_teaserimagefullwidthsmallimage {
  @extend .my-half-special-gutter, .my-lg-special-gutter;

  .tmpl-Landingpage & {
    @extend .my-2h, .my-lg-special-gutter-lp;
  }

  display: -ms-grid;
  display: grid;

  -ms-grid-columns: auto;
  grid-template-columns: auto;

  -ms-grid-rows: auto 0 auto;
  grid-template-rows: auto auto;

  @include media-breakpoint-up(lg) {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  grid-gap: 0;

  & .text-shadow {
    @include media-breakpoint-between(xs, md) {
      text-shadow: none;
    }
  }

  & > div {
    -ms-grid-column: 1;
    grid-column: 1 / 1;

    -ms-grid-row: 2;
    grid-row: 2;

    z-index: 1;
    display: flex;
    align-items: var(--teaser-image-fullwith-align);

    @include media-breakpoint-up(lg) {
      -ms-grid-row: 1;
      grid-row: 1;
    }
  }

  & > figure {
    grid-column: 1 / 1;

    -ms-grid-row: 1;
    grid-row: 1;

    figcaption {
      padding: 0 $grid-gutter-width * 0.5;
    }
  }

  & > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  & > *:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;

    @include media-breakpoint-up(lg) {
      -ms-grid-row: 1;
    }
  }
}

@include ie11-only-wrap-shizzle('.theme_teaserimagefullwidth') {
  & > div,
  & > figure {
    width: 94.5vw;

    @media (max-width: 1440px) {
      width: calc(100vw - 20px);
    }

    @media (min-width: 1441px) {
      width: calc(100vw - 20px);
    }

    position: relative;
  }
}

@include ie11-only-wrap-shizzle('.theme_teaserimagefullwidthsmallimage') {
  & > div,
  & > figure {
    width: 94.5vw;

    @media (max-width: 1440px) {
      width: calc(100vw - 40px);
    }

    @media (min-width: 1441px) {
      width: calc(94.5vw - 40px);
    }

    position: relative;
  }
}

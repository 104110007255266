@charset "UTF-8";

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 42px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 10px;
$hamburger-layer-color: __get($theme-colors, primary);
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(100%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

$hamburger-types: (squeeze);

@import '../../../node_modules/hamburgers/_sass/hamburgers/base';
@import '../../../node_modules/hamburgers/_sass/hamburgers/types/squeeze';

.hamburger {
  height: 24px;

  :has(.navigation-wrapper.open) & {
    background-color: __get($colors, 'white');
  }

  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(sm) {
    transform: scale(0.8333);
  }

  &[aria-expanded='false'] {
    .hamburger__open {
      display: block;
    }

    .hamburger__close {
      display: none;
    }
  }

  &[aria-expanded='true'] {
    .hamburger__open {
      display: none;
    }

    .hamburger__close {
      display: block;
    }
  }
}

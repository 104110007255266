$supi-colors: (
  background: #fff,
  primary: #000,
  success: #000,
  notice: #000,
  button-wrap-bgcolor: transparent
);

$supi-button-wrap-padding: 0;
$supi-banner-box-shadow: 0 0 10px rgba(map-get($supi-colors, primary), 0.3);

$supi-yt-overlay-background: $white;
$supi-yt-overlay-color: $black;

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

:root {
  --supi-color-dismiss: #{$white};
  --supi-color-dismiss-background: #{map-get($theme-colors, 'red')};
  --supi-color-choose: #000;
  --supi-color-choose-invert: #fff;
  --supi-color-button-bar: #{map-get($supi-colors, primary)};
  --supi-color-button-color: #{map-get($supi-colors, background)};
  --supi-color-background: #fff;
  --supi-color-individual-background: #fff;
  --supi-color-allow: #fff;

  --supi-yt-column: 1;
  --supi-yt-row: 1;
  --supi-yt-overlay-background: #{$supi-yt-overlay-background};
  --supi-yt-overlay-color: #{$supi-yt-overlay-color};
  --supi-yt-overlay-padding: 1.5em;
}
.tx-supi {
  &.theme-default {
    width: min(850px, 100%);
    max-width: none;
  }

  &__block-body {
    > ul {
      display: flex;
      flex-direction: column;
      gap: $grid-gutter-width * 0.25;
      margin-top: $grid-gutter-width * 0.25;
    }
  }

  &__link-button[aria-expanded] {
    font-size: 18px;
  }

  &__block-item {
    padding-top: 3px;
    font-size: 18px;
  }

  .tx-supi__button-wrap [role='button']#supi__choose {
    background: #{map-get($supi-colors, background)};
    background: var(--supi-color-choose-invert);

    color: #{map-get($supi-colors, notice)};
    color: var(--supi-color-choose);
  }

  .supi-checkbox label {
    box-shadow: inset 0 0 0 1px #{map-get($supi-colors, primary)};
    background: #{map-get($supi-colors, background)};

    &::before {
      box-shadow: none;
      border: 1px solid #000 !important;
      margin-top: 0;
    }
  }

  button#supi__individualSwitchTo {
    border-color: transparent;
  }
}

.tx_supi_youtube {
  @extend .my-half-special-gutter, .my-lg-special-gutter;
}

.tx-supi {
  &__youtube {
    font-size: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    &-preview-image,
    &-text,
    svg {
      grid-column: var(--supi-yt-column, 1);
      grid-row: var(--supi-yt-row, 1);
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
    }

    &-text {
      --supi-yt-row: 2;
      --supi-yt-overlay-width: 100%;

      position: relative;
      margin: 0;
      padding: 0;
      top: unset;

      @include media-breakpoint-up(md) {
        --supi-yt-row: 1;
        --supi-yt-overlay-background: rgba(255, 255, 255, 0.9);
      }

      p:first-child {
        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }
      }

      display: flex;
      flex-direction: column;
      align-self: center;
      justify-self: center;
      justify-content: center;
      align-items: center;
      width: var(--supi-yt-overlay-width);
      background: var(--supi-yt-overlay-background);

      &__inner {
        background: var(--supi-yt-overlay-background, #fff);
        color: var(--supi-yt-overlay-color, #000);
        padding: var(--supi-yt-overlay-padding);
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1.5em;
        backdrop-filter: blur(3px);

        * {
          font-size: 18px;
        }
      }
    }

    &-buttons {
      display: flex;
      gap: 1em;
      justify-content: space-between;
    }
  }

  &__service {
    &-btn {
      height: 66px;
    }
  }
}

.ce-type-text {
  @extend .pb-5, .pb-lg-gutter175;

  @at-root .main .ce-type-text:last-child {
    @extend .pb-qg;
  }

  p {
    @extend .mb-hg, .mb-lg-fg;
    &:last-child {
      @extend .mb-0;
    }
  }
}

.theme_text {
  @extend .container, .my-half-special-gutter, .my-lg-special-gutter;

  header {
    @include make-row();

    @for $i from 1 through 4 {
      h#{$i},
      .h#{$i} {
        @extend .default-element-width, .mb-hg, .mb-lg-fg;
      }
    }
  }
}

@use 'sass:math';

// load all variables
@import 'breakpoints';
@import 'container';
@import 'icomoon';
@import 'colors';
@import 'aspect-ratios';
@import 'animations';

// main grid settings
$grid-gutter-width: 32px;
$ce-wrapper-max-width: (
  xxl: 1900px,
  ultrawide: 2560px
);

// font settings
$font-base-directory: '../Webfonts/';
$font-family-sans-serif: 'Source Sans Pro', Helvetica, Arial, Geneva, Verdana, sans-serif;
$font-family-link-arrow: Arial, Helvetica, sans-serif;

$base-font-size: (
  default: 16px,
  sm: 16px,
  md: 16px,
  lg: 18px,
  xl: 18px,
  xxl: 18px
);

$headings-font-weight: 700;

// headline default classes for all (top) headlines on all pages
$headline-default-classes: '.col-12, .col-lg-10, .offset-lg-1, .col-xl-8';

$font-settings: (
  default: (
    headings: (
      'h1, .h1': (
        font-size: 40px,
        line-height: 50px
      ),
      'h2, .h2, .theme_teaser-item__title, .news-teaser__item-title': (
        font-size: 28px,
        line-height: 35px
      ),
      '.theme_logo_list h2, .theme_award_list h2, .theme_link_cloud h2, .theme_blockquote h2': (
        font-size: 18px,
        line-height: 25px,
        font-weight: 400,
        margin-bottom: 16px
      ),
      'h3, .h3': (
        font-size: 25px,
        line-height: 32px
      ),
      'h4, .h4': (
        font-size: 22px,
        line-height: 29px
      )
    ),
    global: (
      body: (
        font-size: 1rem
      )
    ),
    text: (
      'p, .bodytext, .form-control, .control-label': (
        font-size: 18px,
        line-height: 28px,
        font-weight: 400,
        font-style: normal
      ),
      '.text-large p, .theme_intro_teaser .teaser_text': (
        font-size: 18px,
        line-height: 28px
      ),
      a: (
        font-weight: 600,
        text-decoration: underline
      ),
      '.link-arrow': (
        font-weight: 600,
        font-size: 18px
      ),
      li: (
        font-size: 20px
      ),
      '.theme_link_cloud li': (
        font-size: 29px,
        line-height: 36px
      ),
      'blockquote, blockquote p': (
        font-size: 28px,
        line-height: 35px
      ),
      '.bodytext p.text_large': (
        font-size: 22px,
        line-height: 29px,
        font-weight: 700
      ),
      '.theme_intro_index .bodytext a': (
        font-weight: 400
      )
    ),
    navigation: (
      '.overlay-navigation a': (
        font-size: 30px,
        font-weight: 700,
        line-height: 60px
      ),
      '.overlay-navigation + .overlay-navigation a': (
        font-size: 16px,
        font-weight: normal,
        line-height: 34px
      )
    )
  ),
  sm: (
    headings: (
      '.news-teaser__item-title, .theme_teaser-item__title': (
        font-size: 30px,
        line-height: 35px
      )
    )
  ),
  md: (
    headings: (
      'h1, .h1': (
        font-size: 60px,
        line-height: 66px
      ),
      'h2, .h2': (
        font-size: 36px,
        line-height: 40px
      ),
      '.theme_teaser-item__title': (
        font-size: 32px,
        line-height: 40px
      ),
      'h3, .h3': (
        font-size: 32px,
        line-height: 40px
      )
    ),
    text: (
      '.text-large p, .theme_intro_teaser .teaser_text': (
        font-size: 24px,
        line-height: 36px
      ),
      '.theme_link_cloud li': (
        font-size: 50px,
        line-height: 55px
      )
    ),
    navigation: (
      '.overlay-navigation a': (
        font-size: 48px,
        line-height: 72px
      ),
      '.overlay-navigation + .overlay-navigation a': (
        font-size: 18px
      )
    )
  ),
  lg: (
    headings: (
      'h1, .h1': (
        font-size: 64px,
        line-height: 70px
      ),
      'h2, .h2': (
        font-size: 45px,
        line-height: 50px
      ),
      '.theme_logo_list h2, .theme_award_list h2, .theme_link_cloud h2, .theme_blockquote h2': (
        font-size: 22px,
        line-height: 33px,
        margin-bottom: 32px
      ),
      '.theme_blockquote h2': (
        margin-bottom: 24px
      ),
      'h3, .h3': (
        font-size: 40px,
        line-height: 48px
      ),
      'h4, .h4': (
        font-size: 40px,
        line-height: 48px
      )
    ),
    text: (
      'p, .bodytext, .form-control, .control-label': (
        font-size: 22px,
        line-height: 34px
      ),
      '.text-large p, .theme_intro_teaser .teaser_text': (
        font-size: 24px,
        line-height: 36px
      ),
      '.link-arrow': (
        font-size: 22px
      ),
      li: (
        font-size: 22px
      ),
      '.theme_link_cloud li': (
        font-size: 56px,
        line-height: 70px
      ),
      'blockquote, blockquote p': (
        font-size: 35px,
        line-height: 45px
      ),
      '.bodytext p.text_large': (
        font-size: 40px,
        line-height: 48px,
        font-weight: 700
      ),
      '.theme_intro_index .bodytext p': (
        font-size: 30px,
        line-height: 45px
      )
    ),
    navigation: (
      '.overlay-navigation a': (
        font-size: 25px
      )
    )
  ),
  xl: (
    headings: (
      '.theme_teaser-item__title': (
        font-size: 36px,
        line-height: 44px
      )
    ),
    text: (
      'blockquote, blockquote p': (
        font-size: 43px,
        line-height: 55px
      )
    ),
    navigation: (
      '.overlay-navigation a': (
        font-size: 32px
      )
    )
  ),
  xxl: (
    headings: (
      'h1, .h1': (
        font-size: 72px,
        line-height: 1.2
      ),
      'h2, .h2': (
        font-size: 56px,
        line-height: 1.2
      ),
      '.theme_teaser-item__title': (
        font-size: 45px,
        line-height: 50px
      ),
      '.news-teaser__item-title': (
        font-size: 38px,
        line-height: 45px
      )
    ),
    text: (
      '.text-large p, .theme_intro_teaser .teaser_text': (
        font-size: 30px,
        line-height: 45px
      )
    ),
    navigation: (
      '.overlay-navigation a': (
        font-size: 45px
      )
    )
  )
);

// colors
$primary: #000;
$body-color: $primary;
$white: #fff;
$lightblue: #eaf6fd;
$lightgrey: #f0f0f0;
$darkgrey: #4a4a4a;
$grey: #767676;
$red: #fe5e5a;
$yellow: #eecc19;

// override or add colors here
// if you need them only in the
// scss context. if you need them
// in the typo3 backend, use the
// config.yaml and run the
// Misc:CONFIG gulp task
$colors: map-merge((), $colors);

$theme-colors: (
  'client-wienxtra': __get($colors, client-wienxtra),
  'light-primary': rgba($primary, 0.75),
  'yt-play-color': $white,
  'yt-button': #000,
  'tagcolor': $lightgrey,
  'red': $red,
  'yellow': $yellow
);

// spacers for margin and padding spaces
// use classnames like px-fg or px-lg-fg
// for full gutter padding on x-axis or
// full gutter padding lg or bigger on x-axis
$spacers: (
  extrasmall: $grid-gutter-width * 0.125,
  small: $grid-gutter-width * 0.25,
  hqp: $grid-gutter-width * 0.375,
  qp: $grid-gutter-width * 0.75,
  hg: $grid-gutter-width * 0.5,
  tq: $grid-gutter-width * 0.75,
  fg: $grid-gutter-width,
  1tq: $grid-gutter-width * 1.75,
  dg: $grid-gutter-width * 2,
  2h: $grid-gutter-width * 2.5,
  tg: $grid-gutter-width * 3,
  qg: $grid-gutter-width * 4,
  navigation-gutter: 10px,
  box-gutter: 60px,
  special-gutter: 100px,
  half-special-gutter: 50px,
  special-gutter-lp: 200px,
  small-special-gutter-lp: 70px,
  gutter125: $grid-gutter-width * 1.25,
  gutter150: $grid-gutter-width * 1.5,
  gutter175: $grid-gutter-width * 1.75,
  gutter225: $grid-gutter-width * 2.25,
  gutter325: $grid-gutter-width * 3.25,
  gutter5: $grid-gutter-width * 5,
  gutter7: $grid-gutter-width * 7
);

// border settings
$border-width: 3px;
$border-color: $primary;

// global animation settings
$animation-settings: (
  speed: 0.3s,
  easing: ease-in-out
);

// buttons
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

// form
$input-focus-color: $primary;
$input-color: $primary;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$input-border-color: transparent;
$input-border-width: 2px;

$input-box-shadow: none;
$input-focus-box-shadow: none;

//modals
$modal-lg: 1200px;
$modal-xl: 1440px;

// badges
$badge-border-radius: 0;

[data-color],
[data-color] a:not(.btn-primary):not(.btn-red) {
  color: var(--teaser-image-fullwidth-color-invert);

  &:hover {
    text-decoration-color: var(--teaser-image-fullwidth-color-invert);
  }

  @include media-breakpoint-up(lg) {
    color: var(--teaser-image-fullwidth-color);

    &:hover {
      text-decoration-color: var(--teaser-image-fullwidth-color);
    }
  }
}

[data-contrast-color] {
  color: var(--contrast-color);
}

@each $key, $value in $theme-colors {
  [data-color='#{$key}'] {
    color: __get($theme-colors, $key);
  }
}

@use 'sass:math';

ul {
  .ce-wrapper &:not(.taglist) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: block;
      padding-left: math.div($grid-gutter-width, 2);
    }
  }

  .bodytext &,
  .cke_editable & {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &.list-default {
    list-style: initial;
    margin-bottom: 1rem;
    padding-left: math.div($grid-gutter-width, 2);
  }
}

.theme_teaser_text,
.theme_teaserimagefullwidthsmallimage,
.theme_teaserimagefullwidth,
.theme_blockquote,
.textpic,
.theme_reviews {
  &[data-bgcolor] {
    background-color: var(--teaser-image-fullwidth-bgcolor);
  }
}

@each $key, $value in $theme-colors {
  [data-bgcolor='#{$key}'] {
    background-color: __get($theme-colors, $key);
  }
}

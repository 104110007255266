.link-arrow {
  display: inline-block;
  position: relative;
  padding-left: 3.5rem;
  line-height: 140%;

  &:before {
    font-family: $font-family-link-arrow;
    font-size: $grid-gutter-width;
    position: absolute;
    content: '→';
    left: 0.75rem;
    top: 0;
    transition: all 0.2s ease-in-out;
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: -2px;
  }

  &:hover {
    &:before {
      transform: translateX(10%);
    }
  }

  .teaser-text-col-content & {
    margin-top: 20px;
  }

  & + & {
    margin-left: $grid-gutter-width * 0.5;
  }
}

@include ie11-only-wrap-shizzle('.link-arrow') {
  text-decoration: none;
}

.taglist {
  --badge-padding: #{$grid-gutter-width * 0.25} #{$grid-gutter-width * 0.5};
  --badge-font-weight: 600;
  --badge-font-size: 16px;
  --badge-gap: #{$grid-gutter-width * 0.25};

  &:has(.badge-grid-teaser) {
    --badge-padding: #{$grid-gutter-width * 0.25} #{$grid-gutter-width * 0.5};
    --badge-gap: 12px;
  }

  @include media-breakpoint-up(xl) {
    --badge-padding: 12px #{$grid-gutter-width * 0.75};
    --badge-font-weight: 600;
    --badge-font-size: 18px;
    --badge-gap: #{$grid-gutter-width * 0.5};

    &:has(.badge-grid-teaser) {
      --badge-padding: 10px 20px;
      --badge-font-size: 16px;
      --badge-gap: #{$grid-gutter-width * 0.5};
    }
  }

  @include media-breakpoint-up(xxl) {
    &:has(.badge-grid-teaser) {
      --badge-font-size: 18px;
    }
  }

  list-style: none;
  margin: 0 0 ($grid-gutter-width * 0.5) 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--badge-gap);

  li {
    margin: 0;
    padding: 0;

    a:has(.badge-grid-teaser) {
      position: relative;
      z-index: 1;
    }
  }

  .badge-grid-teaser {
    color: var(--textcolor);
    background-color: var(--bgcolor);
    outline: 2px solid var(--textcolor);
  }
}

.theme_news_teaser {
  > .container > .row > .header {
    @include make-col-ready();
    @include make-col(12);
  }

  .news-teaser {
    &__scroll {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-padding: 0 $grid-gutter-width * 0.5;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }

    &__item {
      margin-bottom: $grid-gutter-width;

      &-title {
        font-weight: 700;
        margin-bottom: 0;
      }

      &-link {
        text-decoration: none;
        font-weight: inherit;
        hyphens: auto;
        hyphenate-limit-chars: 10;
      }

      &-description {
        font-weight: initial;
        margin-bottom: 0;
      }

      &--scroll {
        min-width: 92%;
        scroll-snap-align: start;
        padding-right: $grid-gutter-width * 0.5;
      }
    }
  }
}

.theme_intro_subpage {
  @extend .container, .my-dg, .my-lg-tg;

  .header {
    @extend #{$headline-default-classes};
    display: flex;
    flex-direction: column;

    .h1 {
      margin-bottom: 0;
      hyphens: auto;
      hyphenate-limit-chars: 10;
      // order: 1;

      @each $heading in (h2, h3, h4, h5) {
        & + #{$heading} {
          @extend .heading-small, .mb-md-hg;
          // order: 0;
        }
      }

      & + .heading-small {
        @extend .mb-md-hg;
      }
    }

    & + [data-subheadline='1'] {
      margin-top: 52px;
    }
  }
}

.sticky {
  position: sticky;

  &__header {
    top: 0;
    z-index: 10150;
    background-color: $white;

    &:has(.navigation-wrapper.open) {
      background-color: unset;
    }
  }
}

@include ie11-only-wrap-shizzle('.sticky') {
  position: fixed;
}

.show-sticky {
  @keyframes fade-up {
    0% {
      transform: translateY(150px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .btn {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

  @include media-breakpoint-down(lg) {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: $grid-gutter-width * 0.75;
    transform: translateY(150px);
    animation: fade-up 0.6s forwards 2s;

    .nav-down & {
      display: block;
    }

    .btn {
      padding: 10px 20px;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
    }
  }

  header:has(.navigation-wrapper.open) & {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    align-content: center;
    margin-left: $grid-gutter-width * 0.5;

    .btn {
      font-size: 18px;
    }
  }
}

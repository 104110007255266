.default-element-width {
  @extend .col-12, .offset-md-1, .col-md-10, .offset-xl-2, .col-xl-8;
}

.column-6,
.column-8,
.column-12 {
  @extend .col-12;
}

.column-6 {
  @extend .col-md-6;

  figcaption {
    span {
      @extend .px-hg;
    }
  }
}

.column-8 {
  @extend .offset-md-1, .col-md-10, .offset-xl-2, .col-xl-8;

  figcaption {
    span {
      @extend .px-hg;
    }
  }
}

.column-12 {
  figcaption {
    max-width: none;

    span {
      @extend .offset-md-1, .col-md-8, .offset-xl-2, .col-xl-6;
    }
  }
}

.modal {
  &-body {
    padding: 0;
  }

  &-content {
    border: none;
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0;

    .modal-newsletter {
      position: relative;

      .hamburger {
        position: absolute;
        top: 0;
        right: 0;
        margin: $grid-gutter-width * 0.5;
        z-index: 2;

        --size: 24px;

        @include media-breakpoint-up(lg) {
          --size: 32px;

          margin: $grid-gutter-width * 1.25;
        }

        width: var(--size);
        height: var(--size);

        &-box {
          width: var(--size);
          height: var(--size);
        }

        &-inner {
          width: var(--size);

          &:before,
          &:after {
            width: var(--size);
          }
        }
      }
    }
  }
}

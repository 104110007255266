blockquote {
  p {
    font-weight: 600;

    &:before {
      content: '„';
    }

    &:after {
      content: '“';
    }
  }
}

.logo {
  //width: auto;
  //standard logo max-width
  //max-width: 150px;

  //10 years logo max-width
  //max-width: 91px;
  height: 33px;
  width: auto;

  //10 years logo max-width
  //@include media-breakpoint-up(sm) {
  //  max-width: 288px;
  //}

  @include media-breakpoint-up(md) {
    height: 45px;
  }

  @include media-breakpoint-up(lg) {
    height: 55px;
  }

  @keyframes bag {
    0% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.5);
    }
    90% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes confetti {
    0% {
      transform: rotate(0deg) scale(1);
      opacity: 0;
    }
    50% {
      transform: rotate(270deg) scale(1.4);
    }
    75% {
      transform: rotate(270deg) scale(1.4);
      opacity: 1;
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  overflow: visible;

  &-confetti {
    transform-origin: 250px 115px;

    &:not(.no-animate) {
      @include motion-safe {
        animation: bag 2s;
      }

      @include media-breakpoint-up(md) {
        transform-origin: 420px 255px;
      }

      .confetti-1 {
        @include motion-safe {
          animation: confetti 1.1s;
        }

        transform-origin: 240px 93px;

        @include media-breakpoint-up(md) {
          transform-origin: 392px 221px;
        }
      }

      .confetti-2 {
        @include motion-safe {
          animation: confetti 1.2s reverse;
        }
        transform-origin: 258px 86px;

        @include media-breakpoint-up(md) {
          transform-origin: 412px 214px;
        }
      }

      .confetti-3 {
        @include motion-safe {
          animation: confetti 0.9s reverse;
        }
        transform-origin: 274px 104px;

        @include media-breakpoint-up(md) {
          transform-origin: 426px 233px;
        }
      }

      .confetti-4 {
        @include motion-safe {
          animation: confetti 1.3s;
        }
        transform-origin: 286px 123px;

        @include media-breakpoint-up(md) {
          transform-origin: 437px 248px;
        }
      }

      .confetti-5 {
        @include motion-safe {
          animation: confetti 1s;
        }
        transform-origin: 284px 143px;

        @include media-breakpoint-up(md) {
          transform-origin: 436px 267px;
        }
      }
    }
  }
}

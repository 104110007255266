figure {
  margin: 0;

  & + & {
    margin-top: __get($spacers, box-gutter);
  }

  &.column-6 {
    margin-top: 0;
  }
}

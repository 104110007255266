/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-base-directory}/source-sans-pro-v13-latin-regular.woff') format('woff');
  font-display: swap;
}

/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('#{$font-base-directory}/source-sans-pro-v13-latin-italic.woff') format('woff');
  font-display: swap;
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-base-directory}/source-sans-pro-v13-latin-600.woff') format('woff');
  font-display: swap;
}

/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('#{$font-base-directory}/source-sans-pro-v13-latin-600italic.woff') format('woff');
  font-display: swap;
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-base-directory}/source-sans-pro-v13-latin-700.woff') format('woff');
  font-display: swap;
}

* {
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.logo-list {
  @extend .py-hg, .py-lg-dg;

  .header {
    @extend .d-none, .d-md-flex;

    @include media-breakpoint-up(md) {
      flex: 1 0 auto;
      margin-right: $grid-gutter-width * 0.5;

      h2 {
        margin: 0;
        display: flex;
        align-items: center;
      }

      & + div {
        flex: 0 1 auto;
      }
    }
  }

  .row .row {
    margin-left: -#{$grid-gutter-width * 0.125};
    margin-right: -#{$grid-gutter-width * 0.125};

    [class*='col'] {
      padding-left: #{$grid-gutter-width * 0.125};
      padding-right: #{$grid-gutter-width * 0.125};
    }
  }

  img {
    display: none;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 4vw;

    @include first(3) {
      display: block;
    }

    @include media-breakpoint-up(md) {
      @include first(4) {
        display: block;
      }
    }
    @include media-breakpoint-up(xxl) {
      @include first(5) {
        display: block;
      }
    }

    max-height: 30px;

    @include media-breakpoint-up(sm) {
      max-height: 50px;
    }

    @include media-breakpoint-up(md) {
      max-height: 40px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 60px;
    }

    @include media-breakpoint-up(xxl) {
      max-height: 75px;
    }
  }
}

.theme_logo_list {
  @extend .my-half-special-gutter, .my-lg-special-gutter, .container, .d-flex, .py-hg, .py-lg-0;

  flex-direction: column;

  .header {
    @extend .col-12, .col-xl-4, .px-0;
  }

  ul {
    --columns: 3;
    --column-gap: #{$grid-gutter-width * 0.75};
    --row-gap: #{$grid-gutter-width};

    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);

    @include media-breakpoint-up(md) {
      --columns: 4;
      --column-gap: #{$grid-gutter-width * 1.75};
      --row-gap: #{$grid-gutter-width};
    }

    @include media-breakpoint-up(lg) {
      --row-gap: #{$grid-gutter-width * 1.25};
    }

    @include media-breakpoint-up(xl) {
      --columns: 6;
    }

    @include media-breakpoint-up(xxl) {
      --row-gap: #{$grid-gutter-width * 1.75};
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;

        &[src*='Logos/mera/logos-mera'] {
          width: 200px;
        }
      }
    }
  }
}

@use 'sass:math';

.form_formframework:has(.form-newsletter) {
  .form-content {
    .header {
      & > * {
        margin-bottom: $grid-gutter-width * 0.75;
      }
    }
  }

  .control-label > span,
  .form-control {
    font-size: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 22px;
    }
  }

  .fieldset-form .control-label > span:first-of-type {
    transform: translateY(37px) scale(1);

    @include media-breakpoint-up(lg) {
      transform: translateY(48.5px) scale(1);
    }
  }

  .form-control {
    border-bottom-color: $primary;
  }

  .clearfix {
    font-size: 18px;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }

  .bodytext p {
    font-size: 18px;
    line-height: 1.5;

    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }

  // newsletter form as CE
  &:not(#newsletterModal &) {
    .container {
      display: block;
    }

    background: $lightblue;
    padding: $grid-gutter-width * 1.5 0;

    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width * 2.25 0;
    }

    .form-control {
      background: $lightblue;
    }

    .form-content,
    .form-newsletter {
      @include media-breakpoint-up(lg) {
        width: percentage(math.div(10, 12));
        margin: auto;
      }
    }

    .form-newsletter {
      form {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }

        .fieldset-form {
          .clearfix {
            margin-bottom: $grid-gutter-width * 1.25;
          }

          @include media-breakpoint-up(lg) {
            width: percentage(math.div(7, 12));

            .clearfix:last-child {
              margin-bottom: 0;
            }
          }
        }

        .form-navigation {
          @include media-breakpoint-up(lg) {
            width: percentage(math.div(5, 12));
            margin-top: $grid-gutter-width;
            margin-left: $grid-gutter-width * 1.75;
          }
        }
      }
    }

    .form-newsletter-illustration {
      display: none;
    }
  }

  // newsletter form as own page
  &.nl-without-bg:not(#newsletterModal &) {
    background-color: transparent;
    padding: 0;

    .header > * {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width * 2;
      }
    }

    .form-content,
    .form-newsletter {
      @include media-breakpoint-up(lg) {
        width: percentage(math.div(9, 12));
        margin-left: percentage(math.div(1, 12));
      }
    }

    .form-content {
      margin-bottom: $grid-gutter-width * 0.75;

      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width * 1.25;
      }
    }

    .form-newsletter {
      form {
        @include media-breakpoint-up(lg) {
          flex-direction: column;
        }

        .form-control {
          background: transparent;
        }

        nav.form-navigation {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  //newsletter form as popup
  #newsletterModal & {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    column-gap: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(lg) {
      grid-template-rows: auto auto;
    }

    .form-content {
      grid-column: 1/3;
      grid-row: 2;
      padding: $grid-gutter-width $grid-gutter-width * 0.5 0;

      @include media-breakpoint-up(lg) {
        grid-column: 1;
        grid-row: 1;
        padding: $grid-gutter-width * 2 0 0 $grid-gutter-width * 2.5;
      }
    }

    .form-newsletter {
      grid-column: 1/3;
      grid-row: 3;
      padding: 0 $grid-gutter-width * 0.5 $grid-gutter-width * 1.25;

      @include media-breakpoint-up(lg) {
        grid-column: 1;
        grid-row: 2;
        padding: 0 0 $grid-gutter-width * 2 $grid-gutter-width * 2.5;
      }

      form {
        .fieldset-form {
          .clearfix {
            margin-bottom: $grid-gutter-width * 0.75;

            @include media-breakpoint-up(lg) {
              margin-bottom: $grid-gutter-width;
            }
          }
        }
      }
    }

    .form-newsletter-illustration {
      grid-column: 1/3;
      grid-row: 1;
      position: relative;
      display: flex;
      padding: $grid-gutter-width $grid-gutter-width * 0.5 0;
      height: 246px;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        grid-column: 2;
        grid-row: 1/3;
        padding: $grid-gutter-width $grid-gutter-width * 2.5 $grid-gutter-width 0;
        height: auto;
      }

      svg {
        z-index: 2;
        width: 100%;
        height: 287px;

        @include media-breakpoint-up(lg) {
          height: auto;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: $lightblue;
        z-index: 1;

        @include media-breakpoint-up(lg) {
          width: percentage(math.div(5, 6));
        }
      }
    }
  }
}

#header {
  --header-height: 65px;
  --bg-color: #{__get($colors, 'white')};

  background-color: var(--bg-color);
  transition-property: top, background-color;

  @include motion-safe() {
    transition-duration: __get($animation-settings, 'speed');
  }

  @include media-breakpoint-up(md) {
    --header-height: 83.5px;
  }

  @include media-breakpoint-up(lg) {
    --header-height: 110.5px;
  }

  body:has(.theme_intro_video) & {
    --bg-color: #{$lightblue};

    box-shadow: none;

    &.scrolled-by {
      --bg-color: #{__get($colors, 'white')};
    }

    @include media-breakpoint-up(xl) {
      &:has(.navigation-main-submenu.open) {
        --bg-color: #{__get($colors, 'white')};
      }
    }
  }

  .container-fluid {
    @each $breakpoint, $maxWidth in $ce-wrapper-max-width {
      @include media-breakpoint-up($breakpoint) {
        max-width: $maxWidth;
      }
    }
  }

  .logo-wrap {
    justify-content: space-between;
    position: relative;

    > div {
      align-content: center;
      width: 100%;

      @include media-breakpoint-up(xl) {
        width: auto;
      }
    }

    &:before {
      content: '';
      opacity: 0;
      position: absolute;
      inset: 0;
      background: #000;
      z-index: -1;
      transition-property: opacity;

      @include motion-safe() {
        transition-duration: __get($animation-settings, 'speed');
      }
    }

    &:has(+ .navigation-wrapper.open) {
      &:before {
        opacity: 0.2;
      }
    }

    .mainlogo {
      display: inline-block;

      &:before,
      &:after {
        display: none;
        text-decoration: none;
      }
    }
  }

  .nav-up & {
    top: calc(var(--header-height) * -1);
  }

  a,
  button:not(:disabled) {
    &:focus-visible {
      z-index: 3;
    }
  }

  .hamburger {
    position: relative;
    z-index: 10;
  }
}

:root {
  --yt-play-width: 75px;
  --yt-play-height: 50px;
  --yt-play-width-md: 100px;
  --yt-play-height-md: 65px;
}

.youtube {
  picture {
    position: relative;
  }

  &-playbutton {
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      &:after {
        transform: scale(1.2);
      }
    }

    &:before {
      transition: 0.5s ease;
      content: '';
      width: var(--yt-play-width);
      height: var(--yt-play-height);
      background: __get($theme-colors, 'yt-button');
      border-radius: 60% / 14%;
      color: __get($theme-colors, 'yt-play-color');
      position: absolute;
      top: 50%;
      margin-top: calc(var(--yt-play-height) / 2 * -1);
      left: 50%;
      margin-left: calc(var(--yt-play-width) / 2 * -1);

      @include media-breakpoint-up(md) {
        width: var(--yt-play-width-md);
        height: var(--yt-play-height-md);
        margin-top: calc(var(--yt-play-height-md) / 2 * -1);
        margin-left: calc(var(--yt-play-width-md) / 2 * -1);
      }
    }

    &:after {
      border-style: solid;
      border-width: 1em 0 1em 1.732em;
      border-color: transparent rgba($white, 0.85);
      content: ' ';
      font-size: 0.75em;
      margin: -1em 0 0 -0.75em;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &-overlay {
    position: absolute;
    display: block;
    opacity: 1;
    bottom: 0;
    padding: 15px;
    transition: 0.5s ease-in-out;
    background: rgba($white, 0.8);

    p {
      margin: 0;
    }
  }
}

@include ie11-only-wrap-shizzle('.youtube-overlay') {
  bottom: 4.5em;
  width: 45em;
}

@include ie11-only-wrap-shizzle('.youtube-playbutton') {
  position: absolute;
  left: 22em;
  top: 12em;
}

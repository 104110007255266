$footer-amount-social-links: 3;
$footer-space-between-social-links: 9;
$footer-left-spacing-lg-social-links: 50;

#footer {
  // general footer spacing
  @extend .mb-tg, .mb-sm-hg, .mb-md-tg, .mb-lg-5;

  @each $breakpoint, $maxWidth in $ce-wrapper-max-width {
    @include media-breakpoint-up($breakpoint) {
      max-width: $maxWidth;
    }
  }

  // supseven logo
  .footer-logo {
    @include media-breakpoint-down(sm) {
      max-width: 160px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      max-height: 52px;
      width: auto;
    }
  }
  // remove default BS4 margin-bottom styles
  ul {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  // address block styling
  address {
    @include media-breakpoint-up(lg) {
      // remove default BS4 margin-bottom styles
      margin-bottom: 0;
    }
    // one address part per line
    > span {
      display: block;
      text-align: center;
      // until the address block is shown in two columns via flex
      @include media-breakpoint-up(lg) {
        text-align: left;
        &.address-street,
        &.address-city {
          flex: 0 1 50%;
        }
      }
      a {
        // prevent breaks in all links of address block (email, tel)
        white-space: nowrap;
      }
    }
    // add spacing between address block and following footer elements
    // according to screendesign
    [href^='tel:'] {
      margin-bottom: __get($spacers, 3);
      @include media-breakpoint-up(lg) {
        margin-bottom: __get($spacers, 0);
      }
    }
  }
  .col-social {
    ul {
      @extend .list-inline, .d-flex, .justify-content-center;
      li {
        @extend .list-inline-item, .d-flex, .justify-content-center, .align-items-center;
        &:not(:last-child) {
          margin-right: rem(18);
        }
      }
    }
    @include media-breakpoint-up(lg) {
      // set max-width of the social nav column on the desktop layout of the footer
      max-width: calc(
        (24px * #{$footer-amount-social-links}) +
          (
            #{$footer-space-between-social-links} * (#{$footer-amount-social-links} - 1) +
              #{$footer-left-spacing-lg-social-links}
          )
      );
    }
  }

  .metanav {
    margin-bottom: rem(30);
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
    .list-inline-item:not(:last-child) {
      @include media-breakpoint-only(xs) {
        margin-right: rem(15);
      }
    }
  }
}

@include ie11-only-wrap-shizzle('.col-social') {
  max-width: none !important;
  width: 100%;
  display: block !important;
  flex: none;
  margin-top: 40px;
}

.theme_teaser_text {
  @extend .my-half-special-gutter, .my-lg-special-gutter;

  .tmpl-Landingpage & {
    @extend .my-2h, .my-lg-special-gutter-lp;
  }

  &.mt-0 {
    margin-top: 0 !important;
  }

  &[data-bgcolor] {
    @extend .py-half-special-gutter, .py-lg-small-special-gutter-lp;
  }

  .header,
  .aside {
    @extend .col-12, .col-lg-6, .col-xl-5, .col-xxl-4, .offset-xl-1, .mb-hg, .mb-lg-0, .text-center, .text-md-left;

    #newsletter_register-177 & {
      @extend .text-left, .text-md-center;
    }
  }

  .article {
    @extend .col-12, .col-lg-6, .col-xxl-5, .offset-xxl-1;
    padding-top: 6px;

    p {
      @extend .text-center, .text-md-left;

      #newsletter_register-177 & {
        @extend .text-left, .text-md-center;
      }
    }
  }

  &.columns-12 {
    .header,
    .aside {
      @extend .col-12, .col-lg-5, .offset-lg-0;
    }

    .article {
      @extend .col-12, .col-lg-6, .offset-lg-1;
    }
  }

  .logos {
    display: flex;
    flex-direction: row;
    margin-left: -$grid-gutter-width * 0.5;
    margin-right: -$grid-gutter-width * 0.5;
    margin-top: __get($spacers, box-gutter) * 0.5;

    @include media-breakpoint-up(lg) {
      margin-top: __get($spacers, box-gutter);
    }

    padding: 0;

    li {
      padding: 0 #{$grid-gutter-width * 0.5};

      &:before {
        display: none;
      }
    }
  }
}

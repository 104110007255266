.textpic {
  @extend .my-half-special-gutter, .my-lg-special-gutter;

  &[data-bgcolor] {
    @extend .py-half-special-gutter, .py-lg-small-special-gutter-lp;
  }

  .tmpl-Landingpage & {
    @extend .my-2h, .my-lg-special-gutter-lp;
  }

  &.primary-color {
    a:not(.btn) {
      color: $white;
    }
  }

  .side .bodytext {
    margin-left: 0;
    max-width: 100%;
    flex: 0 0;
  }

  .images {
    &:not(.side) {
      @extend .mt-fg;
    }

    &.side {
      @include media-breakpoint-down(md) {
        order: -1;
      }
    }
  }
}

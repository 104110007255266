@use 'sass:math';

.theme_teaser {
  margin-top: __get($spacers, 'half-special-gutter');
  margin-bottom: __get($spacers, 'half-special-gutter');

  @include media-breakpoint-up(xl) {
    margin-top: __get($spacers, 'special-gutter');
    margin-bottom: __get($spacers, 'special-gutter');
  }

  &:has(+ .theme_teaser) {
    margin-bottom: 0;
  }

  + .theme_teaser {
    margin-top: 0;
  }

  &-item {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &__content {
      &.top {
        //.clickarea {
        //  background-image: linear-gradient(
        //    180deg,
        //    rgba(var(--rgb-background-color), 0.75) 0%,
        //    rgba(var(--rgb-background-color), 0.75) 45%,
        //    rgba(var(--rgb-background-color), 0.01) 100%
        //  );
        //}
      }

      &.bottom {
        //.clickarea {
        //  background-image: linear-gradient(
        //    0deg,
        //    rgba(var(--rgb-background-color), 0.75) 0%,
        //    rgba(var(--rgb-background-color), 0.75) 45%,
        //    rgba(var(--rgb-background-color), 0.01) 100%
        //  );
        //}
      }

      .heading,
      img,
      svg {
        position: relative;
        pointer-events: none;
        z-index: 1;
      }

      .heading-small {
        line-height: $grid-gutter-width * 0.75;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }

    a {
      color: inherit;
      text-decoration: inherit;
      font-weight: inherit;
    }

    background: var(--bgcolor);
    flex: 1 0 percentage(math.div(12, 12));
    max-width: percentage(math.div(12, 12));

    @include media-breakpoint-up(md) {
      flex: 1 0 percentage(math.div(6, 12));
      max-width: percentage(math.div(6, 12));
    }

    justify-content: var(--pos);

    display: -ms-grid;
    display: grid;

    -ms-grid-columns: auto;
    grid-template-columns: auto;

    -ms-grid-rows: auto;
    grid-template-rows: auto;

    grid-gap: 0;

    & > div {
      --padding-top: 0;
      --padding-bottom: 0;

      @extend .px-fg, .px-xxl-1tq;

      -ms-grid-column: 1;
      grid-column: 1 / 1;

      -ms-grid-row: 1;
      grid-row: 1;

      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: var(--justify-content);
      color: var(--textcolor);
      padding-top: var(--padding-top);
      padding-bottom: var(--padding-bottom);

      svg {
        fill: var(--logocolor-primary);
        height: 50px;
        width: auto;
        max-width: 75%;

        @include media-breakpoint-up(xl) {
          max-width: 50%;
        }
      }
    }

    & > figure,
    .figure {
      -ms-grid-column: 1;
      grid-column: 1 / 1;

      -ms-grid-row: 1;
      grid-row: 1;

      // all elements but the first 3 have
      // a 50 percentage width ratio
      picture,
      .ar-holder {
        @extend .aspect-ratio-632by723, .aspect-ratio-lg-950by723;
        display: none;

        & + picture,
        & + .ar-holder {
          display: block;
        }
      }
    }

    & *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    // the first 3 entries have a smaller aspect ratio
    @include first(1) {
      flex: 1 0 percentage(math.div(12, 12));
      max-width: percentage(math.div(12, 12));

      @include media-breakpoint-up(xl) {
        flex: 1 0 percentage(math.div(4, 12));
        max-width: percentage(math.div(4, 12));

        .big-teaser & {
          flex: 1 0 percentage(math.div(12, 12));
          max-width: percentage(math.div(12, 12));
        }
      }

      picture,
      .ar-holder {
        .small-teaser & {
          @extend .aspect-ratio-632by723, .aspect-ratio-md-16by9, .aspect-ratio-xl-632by723;
        }

        @include media-breakpoint-between(md, lg) {
          display: block;

          & + picture,
          & + .ar-holder {
            display: none;
          }
        }

        .big-teaser & {
          @extend .aspect-ratio-632by723, .aspect-ratio-xl-16by9;

          @include media-breakpoint-between(md, lg) {
            display: block;

            & + picture,
            & + .ar-holder {
              display: none;
            }
          }
        }
      }
    }

    @include between(2, 3) {
      flex: 1 0 percentage(math.div(12, 12));
      max-width: percentage(math.div(12, 12));

      @include media-breakpoint-up(md) {
        flex: 1 0 percentage(math.div(6, 12));
        max-width: percentage(math.div(6, 12));
      }

      @include media-breakpoint-up(xl) {
        flex: 1 0 percentage(math.div(4, 12));
        max-width: percentage(math.div(4, 12));
      }

      .big-teaser & {
        @include media-breakpoint-up(md) {
          flex: 1 0 percentage(math.div(6, 12));
          max-width: percentage(math.div(6, 12));
        }
      }

      picture,
      .ar-holder {
        @extend .aspect-ratio-632by723;
      }
    }
  }
}

@include ie11-only-wrap-shizzle('.theme_teaser-item') {
  & > div,
  & > figure,
  .figure {
    width: 32vw;
    position: relative;
  }
}

.theme_reviews {
  @extend .my-half-special-gutter, .my-lg-special-gutter;

  .tmpl-Landingpage & {
    @extend .my-2h, .my-lg-special-gutter-lp;
  }

  &.mt-0 {
    margin-top: 0 !important;
  }

  &[data-bgcolor] {
    @extend .py-half-special-gutter, .py-lg-small-special-gutter-lp;
  }

  .header {
    > * {
      margin: 0;
      text-wrap: balance;
    }
  }

  &__wrap {
    gap: $grid-gutter-width * 0.75;

    @include media-breakpoint-up(md) {
      gap: $grid-gutter-width * 2;
    }
  }

  &__badge {
    gap: $grid-gutter-width * 0.75;

    figure {
      flex: 0 48px;
      max-width: 48px;

      @include media-breakpoint-up(md) {
        padding-top: 12px;
      }

      svg {
        width: 100%;
      }
    }

    &-line1 {
      font-size: 22px;

      @include media-breakpoint-up(lg) {
        font-size: 26px;
      }
    }

    &-line2 {
      font-size: 18px;

      @include media-breakpoint-up(lg) {
        font-size: 22px;
      }
    }
  }

  &__stars {
    color: $yellow;
  }
}

.theme_intro_video {
  @extend .mb-2h, .mb-lg-special-gutter-lp;

  .heading-small {
    font-size: 22px;
    line-height: 45px;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 26px;
      line-height: 60px;
    }
  }

  .hero-video {
    &__top {
      position: relative;
      padding-bottom: 225px;

      &-ctabtn {
        margin-top: $grid-gutter-width;

        @include media-breakpoint-up(lg) {
          margin-top: $grid-gutter-width * 1.25;
        }
      }

      .bodytext {
        p {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: $grid-gutter-width * 0.25;

          @include media-breakpoint-up(lg) {
            font-size: 30px;
            line-height: 45px;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $lightblue;
        z-index: -1;
      }
    }

    &__bottom {
      margin-top: -225px;
    }
  }

  .embed-responsive-16by9 {
    aspect-ratio: 16/9;

    &:before {
      padding-top: 0;
    }

    picture {
      height: 100%;
    }

    img {
      aspect-ratio: 16/9;
      object-fit: cover;
      height: 100%;
    }

    + .youtube-overlay {
      background-color: transparent;
    }
  }
}

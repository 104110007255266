form {
  .theme_teaser_text .container,
  .theme_teaser_text .header,
  .theme_teaser_text .bodytext {
    margin: 0 !important;
    max-width: 100% !important;
    padding-left: 0;
    padding-right: 0;
  }

  .theme_teaser_text .header h2 {
    @extend .h1, .mb-hg, .mb-xl-fg;
  }
}

.ce-wrapper {
  @extend .mx-auto;

  @each $breakpoint, $maxWidth in $ce-wrapper-max-width {
    max-width: 100%;

    @include media-breakpoint-up($breakpoint) {
      max-width: $maxWidth;
    }
  }
}
